import type { HubOrganisation, HubOrganisationPermission } from '~/types/organisation'
import type {
  TreeNodeWithKey,
  CollectionOfSelectedTreeNodes,
  SelectedTreeNode,
  TreeTableNodeWithKey
} from '~/types/tree'

export function getAllNodeKeys(nodesArray: Array<TreeNodeWithKey>): Array<string> {
  const nodeKeys: Array<string> = []

  for (const node of nodesArray) {
    nodeKeys.push(node.key)
    if (node.children) {
      nodeKeys.push(...getAllNodeKeys(node.children))
    }
  }

  return nodeKeys
}
export function getAllNodesFlattened(
  nodesArray: Array<TreeNodeWithKey>
): Array<{ key: string, leaf: boolean, node: TreeNodeWithKey }> {
  const nodeKeys: Array<{ key: string, leaf: boolean, node: TreeNodeWithKey }> = []

  for (const node of nodesArray) {
    nodeKeys.push({ key: node.key, leaf: !node.children?.length, node })
    if (node.children) {
      nodeKeys.push(...getAllNodesFlattened(node.children))
    }
  }

  return nodeKeys
}

export function getSelectedNodesFromGivenParam(
  paramToCheck: string,
  permissibleOptions: Array<string> | string,
  nodesArray: Array<TreeNodeWithKey>
): CollectionOfSelectedTreeNodes {
  if (!permissibleOptions.length || !permissibleOptions) {
    return {}
  }

  if (typeof permissibleOptions === 'string') {
    permissibleOptions = [permissibleOptions]
  }

  const nodeKeys: string[] = []

  const traverseChild = (node: TreeNodeWithKey) => {
    if (node.children) {
      node.children.forEach(child => traverseChild(child))
    }
    if (node.data.item[paramToCheck] && permissibleOptions.includes(node.data.item[paramToCheck])) {
      nodeKeys.push(node.key)
    }
  }

  nodesArray.forEach(node => traverseChild(node))

  return getSelectedNodesFromKeys(nodeKeys, nodesArray)
}

export function getSelectedNodesFromKeys(
  selectedKeys: Array<string> | string,
  nodesArray: Array<TreeNodeWithKey>
): CollectionOfSelectedTreeNodes {
  if (!selectedKeys.length || !selectedKeys) {
    return {}
  }

  if (typeof selectedKeys === 'string') {
    selectedKeys = [selectedKeys]
  }

  const nodeKeys: Array<string> = getAllNodeKeys(nodesArray)
  // Filter the keys to only include the ones that are selected
  const checkedNodeKeys: Array<string> = nodeKeys.filter(key => selectedKeys.some(outlet => key.includes(outlet)))

  // get parent keys of the checked nodes
  const partialCheckedNodeKeys: Array<string> = []
  for (const key of checkedNodeKeys) {
    const keyArray = key.split('-')
    for (let i = 0; i < keyArray.length; i++) {
      const parentKey = keyArray.slice(0, i + 1).join('-')
      if (!checkedNodeKeys.includes(parentKey)) {
        partialCheckedNodeKeys.push(parentKey)
      }
    }
  }

  // Create a new CollectionOfSelectedTreeNodes with the filtered keys
  const selectedNodes: CollectionOfSelectedTreeNodes = {}
  for (const key of checkedNodeKeys) {
    selectedNodes[key] = { checked: true, partialChecked: false }
  }
  for (const key of partialCheckedNodeKeys) {
    selectedNodes[key] = { checked: false, partialChecked: true }
  }

  return selectedNodes
}

export function getSelectedNodeObjectsFromKeys(
  selectedKeys: Array<string> | string,
  nodesArray: Array<TreeNodeWithKey>
): Array<{ key: string, leaf: boolean, node: TreeNodeWithKey }> {
  if (!selectedKeys.length || !selectedKeys) {
    return []
  }

  const nodeKeys: Array<{ key: string, leaf: boolean, node: TreeNodeWithKey }> = getAllNodesFlattened(nodesArray)
  const keysAsArray = Array.isArray(selectedKeys) ? selectedKeys : [selectedKeys]

  // Filter the keys to only include the ones that are selected
  const checkedNodes: Array<{ key: string, leaf: boolean, node: TreeNodeWithKey }> = nodeKeys.filter(node =>
    keysAsArray.some(outlet => node.key.includes(outlet))
  )

  return checkedNodes
}

export function getSelectedKeysFromNodes(
  selectedTreeNodes: CollectionOfSelectedTreeNodes,
  splitKey: boolean = true
): Array<string> {
  if (!splitKey)
    return Object.entries(selectedTreeNodes).reduce<Array<string>>((acc, [key, value]) => {
      if (value.checked) {
        acc.push(key)
      }
      return acc
    }, [])

  const selectedKeys: string[] = []

  for (const [index, node] of Object.entries(selectedTreeNodes)) {
    const treeNode = node as SelectedTreeNode

    const keyArray = index.split('-')
    const key = keyArray[keyArray.length - 1]

    if (treeNode.partialChecked) {
      continue
    }

    // check for any objects that are a child of the checked ones + ignore them
    if (keyArray.some(key => selectedKeys.includes(key))) {
      continue
    }

    if (treeNode.checked) {
      selectedKeys.push(key)
    }
  }

  return selectedKeys
}

export function findNodesByKeys(
  selectedKeys: Array<string> | string,
  nodesArray: Array<TreeNodeWithKey>
): Array<{ key: string, leaf: boolean, node: TreeNodeWithKey }> {
  if (!selectedKeys.length || !selectedKeys) {
    return []
  }
  const keysAsArray = Array.isArray(selectedKeys) ? selectedKeys : [selectedKeys]
  const keysToFind = generateAncestorKeys(keysAsArray, nodesArray)
  const foundNodes = findNodesByKeysInternal(nodesArray, keysToFind)

  return foundNodes.map(node => ({
    key: node.key,
    leaf: !node.children?.length,
    node
  }))
}

const distinctPermissions: HubOrganisationPermission[] = [
  'none',
  'viewAnalytics',
  'manageTruRatgets',
  'manageBranches',
  'viewActivationDetails',
  'manageUsers',
  'viewBilling',
  'manageQuestions',
  'powerUsers'
]

export function permissionsToTreeNodeWithKey(permissions: HubOrganisation): TreeTableNodeWithKey {
  const permissionsToTreeNodeWithKeyInternal = (permissions: HubOrganisation): TreeTableNodeWithKey => {
    const permissionForNode = distinctPermissions.reduce((acc, permission) => {
      return {
        ...acc,
        [permission]: {
          checked: permissions.item.calculatedPermission.includes(permission),
          indeterminate: false
        }
      }
    }, {})

    const nodeType = getNodeType(permissions.item.organisationNodeType as 'headquarters' | 'outletGroup' | 'outlet')

    let icon = undefined
    if (nodeType === 'Outlet' && permissions.item.touchpoint) {
      icon = 'i-mdi-feedback-outline'
      switch (permissions.item.touchpoint.toLowerCase()) {
        case 'online':
          icon = 'i-mdi-earth'
          break
        case 'instore':
          icon = 'i-mdi-storefront-outline'
          break
      }
    }

    const node: TreeTableNodeWithKey = {
      key: permissions.item.organisationNodeId.toString(),
      data: {
        outlet: permissions.item.organisationNodeName!,
        type: nodeType,
        touchpoint: permissions.item.touchpoint,
        icon,
        disabled: permissions.item.disabled,
        permissions: permissionForNode
      },
      children: []
    }

    if (permissions.children.length) {
      node.children = permissions.children.map(child => permissionsToTreeNodeWithKey(child))
    }

    return node
  }

  const node = permissionsToTreeNodeWithKeyInternal(permissions)

  return node
}

export function treeNodeWithKeyToPermissions(
  currentPermissions: HubOrganisation,
  updatedNodes: TreeTableNodeWithKey[]
) {
  const node = findNodeByKey(
    currentPermissions.item.organisationNodeId.toString(),
    updatedNodes)! as TreeTableNodeWithKey
  const selectedKeys = Object.entries(node.data.permissions)
    .filter(([, value]) => {
      return value.checked
    })
    .map(([perm]) => perm) as HubOrganisationPermission[]
  currentPermissions.item.calculatedPermission = selectedKeys
  currentPermissions.children = currentPermissions.children.map(child =>
    treeNodeWithKeyToPermissions(child, updatedNodes)
  )
  return currentPermissions
}

export function updatePermissions(
  nodes: TreeTableNodeWithKey[],
  permission: string,
  selectedNode: TreeTableNodeWithKey
): TreeTableNodeWithKey[] {
  const internalCascadeDown = (node: TreeTableNodeWithKey, parentValue: boolean): TreeTableNodeWithKey => {
    node.children = node.children?.map(child => internalCascadeDown(child, parentValue))
    node.data.permissions[permission].checked = parentValue
    return node
  }

  const internalUpdate = (node: TreeTableNodeWithKey): TreeTableNodeWithKey => {
    if (node.key === selectedNode.key) {
      const parentValue = !node.data.permissions[permission].checked
      node.children = node.children?.map(child => internalCascadeDown(child, parentValue))
      node.data.permissions[permission].checked = parentValue
    } else {
      node.children = node.children?.map(child => internalUpdate(child))
    }

    return node
  }

  const internalAnyChildrenWithValue = (nodes: TreeTableNodeWithKey[], value: boolean): boolean => {
    return nodes.some(node => {
      return (
        node.data.permissions[permission].checked === value
        || (node.children ? internalAnyChildrenWithValue(node.children, value) : true)
      )
    })
  }

  const internalCheckChildren = (node: TreeTableNodeWithKey): TreeTableNodeWithKey => {
    const anyChildrenUnchecked = internalAnyChildrenWithValue(node.children!, false)
    if (anyChildrenUnchecked) {
      node.data.permissions[permission].checked = false
    }

    if (node.children) {
      node.children = node.children.map(child => {
        return internalCheckChildren(child)
      })
    }

    return node
  }

  const internalIndeterminate = (node: TreeTableNodeWithKey): TreeTableNodeWithKey => {
    // TODO: Implement this
    return node
  }

  const updatedNodes = nodes
    .map(node => internalUpdate(node))
    .map(node => internalCheckChildren(node))
    .map(node => internalIndeterminate(node))

  return updatedNodes
}

export function validateSelectedKeys(selectedKeys: CollectionOfSelectedTreeNodes, nodesArray: Array<TreeNodeWithKey>):
CollectionOfSelectedTreeNodes {
  const actualSelectedKeys: CollectionOfSelectedTreeNodes = {}
  const checkedNodeKeys: Array<string> = Object.entries(selectedKeys).filter(([, node]) => node.checked)
    .map(([key]) => key)
  const partialCheckedNodeKeys: Array<string> = Object.entries(selectedKeys).filter(([, node]) => node.partialChecked)
    .map(([key]) => key)

  for (const key of partialCheckedNodeKeys) {
    actualSelectedKeys[key] = { checked: false, partialChecked: true }
  }

  for (const key of checkedNodeKeys) {
    const node = findNodeByKey(key, nodesArray) as TreeNodeWithKey

    if (!node) {
      continue
    }

    if (!node.children || !node.children.length) {
      actualSelectedKeys[key] = { checked: true, partialChecked: false }
      continue
    }

    let allChildrenChecked = true
    for (const child of node.children) {
      if (!checkedNodeKeys.includes(child.key)) {
        allChildrenChecked = false
        break
      }
    }

    actualSelectedKeys[key] = { checked: allChildrenChecked, partialChecked: !allChildrenChecked }
  }

  return actualSelectedKeys
}

function findNodeByKey(key: string, nodes: Array<TreeTableNodeWithKey> | Array<TreeNodeWithKey>):
  TreeTableNodeWithKey | TreeNodeWithKey | undefined {
  for (const node of nodes) {
    if (node.key === key) {
      return node
    }
    if (node.children) {
      const foundNode = findNodeByKey(key, node.children)
      if (foundNode) {
        return foundNode
      }
    }
  }
}

function getNodeType(type: 'headquarters' | 'outletGroup' | 'outlet'): 'ALL OUTLETS' | 'Group' | 'Outlet' {
  switch (type) {
    case 'headquarters':
      return 'ALL OUTLETS'
    case 'outletGroup':
      return 'Group'
    case 'outlet':
      return 'Outlet'
  }
}

function generateAncestorKeys(selectedKeys: string[], treeNodes: TreeNodeWithKey[]): Set<string> {
  const allKeys = new Set<string>()

  // Helper to check if any part of the node's key path matches any selected key
  function checkAndAddKey(key: string): void {
    const parts = key.split('-')
    let currentPath = ''

    for (const part of parts) {
      currentPath = currentPath ? `${currentPath}-${part}` : part
      if (selectedKeys.includes(part)) {
        // Add all keys up to and including the matched selected key
        let tempPath = ''
        for (const ancestor of parts) {
          tempPath = tempPath ? `${tempPath}-${ancestor}` : ancestor
          allKeys.add(tempPath)
          if (selectedKeys.includes(ancestor)) break // Stop after adding the matched selected key
        }
      }
    }
  }

  // Recursively check all nodes
  function traverse(nodes: TreeNodeWithKey[]) {
    for (const node of nodes) {
      checkAndAddKey(node.key)
      if (node.children) traverse(node.children)
    }
  }

  traverse(treeNodes)
  return allKeys
}

function findNodesByKeysInternal(nodes: TreeNodeWithKey[], keysToFind: Set<string>): TreeNodeWithKey[] {
  const foundNodes: TreeNodeWithKey[] = []

  function traverse(nodes: TreeNodeWithKey[]) {
    for (const node of nodes) {
      if (keysToFind.has(node.key)) {
        foundNodes.push(node)
      }
      if (node.children) traverse(node.children)
    }
  }

  traverse(nodes)
  return foundNodes
}
